
html, body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden !important;
  width: 100%;


}
.background{
  //background-image: url("../images/home/bg/Lilac (Cool).jpg");
  background-repeat:repeat-y;
  background-attachment:scroll;
  background-size: contain;
}
html {
  box-sizing: border-box;
}
iframe {
  pointer-events: none;
}
*,
*::after,
*::before {
  box-sizing: inherit;
  outline: 0 !important;
}

.dark {
  background-color: #212121;
}

main {
  color: #2c2c2c;
  //padding: 10px;
  overflow: auto;
}

.container {
  max-width: 1170px;
  margin: 0 auto;

}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-bottom: 50px;
}