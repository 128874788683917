.form{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 200px;

  label {
    color: #c5a447;
  }

  input{
    width: 260px;
    height: 35px;
    padding-left: 10px;
  }
  textarea {
    width: 260px;
    height: 95px;
    padding-left: 10px;
  }
  button{
    padding: 10px;
    background: #eeb445;
    color: white;
    font-size: 19px;
    cursor: pointer;
  }
  input, input:focus-within {
    box-sizing: border-box;
    border: 1px solid #454545;
    background: #f7f5fa;
    height: 40px;
    //width: 30vw;
    padding: 10px 15px;
    color: #000;
  }
   input,  input:-webkit-autofill,  input:-webkit-autofill:active,  input:-webkit-autofill:focus,  input:-webkit-autofill:hover,  input:active,  input:focus,  input:hover,  select:-webkit-autofill,  select:-webkit-autofill:active,  select:-webkit-autofill:focus,  select:-webkit-autofill:hover,  textarea,  textarea:-webkit-autofill,  textarea:-webkit-autofill:active,  textarea:-webkit-autofill:focus,  textarea:-webkit-autofill:hover,  textarea:active,  textarea:focus,  textarea:hover {
    -webkit-box-shadow: 0 0 0 1000px #f7f5fa inset!important;
  }
}



@media (max-width: 575px){
  .form{
    flex-direction: column;
    align-items: center;
    height: 337px;
    margin-bottom: 30px;
  }
}