.our-services{
  margin: 100px 0 50px;
  .our-services_title_wrap{
    max-width: 1140px;
      h2{
    margin: 100px 0 50px;
    color: #c5a447;
    font-size: 42px;
    text-align: center;
  }
  p{
    font-size: 22px;
    color: #c5a447;
    text-align: center;
  }
  }

  .our-services__wrap{
    .items{
      display: flex;
      padding: 15px;
      flex-wrap: wrap;
      justify-content: center;
      .item{
        h3,p{
          display: none;
        }
        img{
          width: 400px;
          border-radius: 20px;
        }

        margin:20px;
      }
    }
  }
}

@media (max-width:500px){
  .our-services{
    h2{
      margin: 100px 0 50px;
    }
    .our-services__wrap{
      .items{
        display: flex;
        padding: 15px;
        flex-wrap: wrap;
        justify-content: center;
        .item{
          h3,p{
            display: none;
          }
          img{
            width: 300px;
            border-radius: 20px;
          }

          margin:20px;
        }
      }
    }
  }
}