.what_is_work {
  h2{
    text-align: center;
    margin: 150px;
    color: #c5a447;
    font-size: 42px;
  }
  width: auto;
  height: auto;
  //background-size: contain;
  background-repeat: no-repeat;
  .timeline-text{
    width: 210px;
  }
}
@media (max-width: 620px){
  .what_is_work h2{
    margin: 100px 0 50px;
  }
}

//-------- how is work ---------
.ant-timeline-item-content {
  display: flex;
  flex-direction: row;

  .timeline-text {
    margin-right: 20px;
    color: white;

    p {
      margin: 0;
      color: #ffffff;
    }
    .timeline-text__phone a{
      color: #6c910c;
    }
  }
  img{
    border-radius: 20px;
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 620px) {
  .ant-timeline-item-content {
    display: flex;
    flex-direction: column;
    img {
      margin-top: 20px;
    }
    .timeline-text {
      margin-right: 20px;
      color: white;

      p {
        margin: 0;
      }
    }
  }
}

@media (max-width: 400px) {
  .ant-timeline-item-content {
    padding-left: 10px;
    img {
      margin-top: 10px;
      width: 200px;
      height: auto;
    }
  }
}

//-------- end how is work ---------