.home-footer{
  text-align: center;
  height: 60px;
  color: #ffffff;
  a{
    color: #747474;
    &:hover{
      color: #e7c705;
    }
  }
}