.section-1 {
  background-image: url(../images/catering.webp);
  background-position: 50% 50%;
  object-fit: cover;
  vertical-align: middle;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
  .top-content{
    margin: 0 auto;
    padding: 50px;
    display: flex;
    height: 100vh;
    min-width: 1140px;
    align-items: flex-start;
    justify-content: space-between;
    img{
      width: 270px;
      height: auto;
    }
    h1{
      color: #c5a447;
      margin-top: calc(100vh - 200px);
      font-size: 44px;
      text-align: center;
    }
  }
}

@media (max-width:1980px){
  .section-1 {
    .top-content{
      margin: 0 auto;
      padding: 50px 10px;
      display: flex;
      height: 100vh;
      min-width: 800px;
      align-items: flex-start;
      justify-content: space-between;
      img{
        width: 182px;
        height: auto;
      }
      h1{
        margin-top: calc(100vh - 160px);
        font-size: 24px;
      }
    }
  }
}

@media (max-width:1200px){
  .section-1 {
    .top-content{
      margin: 0 auto;
      padding: 50px 10px;
      display: flex;
      height: 100vh;
      min-width: 491px;
      align-items: flex-start;
      justify-content: space-between;
      img{
        width: 190px;
        height: auto;
      }
      h1{
        margin-top: calc(100vh - 160px);
        font-size: 24px;
      }
    }
  }
}

@media (max-width:500px){
  .section-1 {
    .top-content{
      margin: 0 auto;
      padding: 31px 10px;
      display: flex;
      height: 100vh;
      min-width: 300px;
      align-items: flex-start;
      justify-content: space-between;
      img{
        width: 155px;
        height: auto;
      }
      h1{
        margin-top: calc(100vh - 136px);
        font-size: 24px;
      }
    }
  }
}