.contact {
  display: block;
  max-width: 591px;
  margin: 0 auto 80px;
  position: relative;
  padding: 15px;

  
  p{
    text-align: center;
    color: greenyellow;
  }

  //img.strawberry_left {
  //  position: absolute;
  //  right: 450px;
  //  bottom: -20px;
  //  width: 150px;
  //}
  //
  //img.strawberry_right {
  //  position: absolute;
  //  left: 450px;
  //  bottom: -20px;
  //  width: 150px;
  //}

  //img.contact.citrus_r{
  //  display: none;
  //}

  h2 {
    text-align: center;
    margin: 150px 0 90px;
    color: #c5a447;
    font-size: 42px;
  }

  .contact_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 50px;
    .contact_block {
      span {
        margin-right: 10px;
        color: #ffffff;
      }

      a {
        color: #767676;

        &:hover {
          color: #e7c705;
        }
      }
    }

    .contact_address {
      width: 158px;
      display: flex;
      justify-content: space-between;
      p, span {
        margin: 0;
        color: #ffffff;
      }
    }
  }
}

@media (max-width: 825px) {
  .contact img.strawberry_left, .contact img.strawberry_right {
    display: none;
  }
}

@media (max-width: 450px) {
  .contact .contact_content {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .contact_block {
      margin-bottom: 10px;
    }

    .contact_address {
      //display: flex;
      p {
        margin-right: 10px;
      }
    }
  }
}

//.ant-modal-title {
//  text-align: center;
//}
//
//.ant-modal-content {
//  display: flex;
//  flex-direction: column;
//
//  #name, #email {
//    width: 80%;
//    margin-bottom: 10px;
//  }
//
//  #message {
//    margin-bottom: 10px;
//  }
//
//  #btn {
//    width: 150px;
//    font-size: 20px;
//    font-weight: 600;
//    color: #505c66;
//    //height: auto;
//    border: solid 1px #505c66;
//
//    padding: 3px 10px;
//    background-color: #ffffff;
//    cursor: pointer;
//
//    &:hover {
//      background-color: #395ee6;
//      color: #ffffff;
//      border: solid 1px #395ee6;
//    }
//  }
//}
//
//.ant-modal-footer {
//  display: none;
//}